import { useQuery } from "@apollo/client";
import { useStaticQuery, graphql } from "gatsby";
import { CONTENTFUL_PRODUCT } from "../queries/contentful/product";

import { contentfulProductsMapper } from "../mappers/contentful/productsMapper";

const useProduct = (ssrData) => {
  if (process.env.GATSBY_CONTENTFUL_HOST) {
    const { data } = useQuery(CONTENTFUL_PRODUCT, {
      variables: { id: ssrData.contentfulProdukt.contentful_id },
    });

    return contentfulProductsMapper(data);
  }
  return ssrData?.contentfulProdukt;
};
export default useProduct;
