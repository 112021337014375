import React from "react"
import { graphql } from 'gatsby';
import Wrapper from '../components/wrapper'
import useProduct from '../hooks/useProduct'
import Gallery from '../components/gallery'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Contact from '../components/contact'

const ProductPage = ({ pageContext: { id, contentful_id }, data }) => {
  const product = useProduct(data)

  const description = process.env.GATSBY_CONTENTFUL_HOST ?
    product?.description?.json
    : product?.description?.raw && JSON.parse(product?.description?.raw);
  return (
    <Wrapper image={product?.images[0]} title={product?.name}>
      <h1 style={{ paddingTop: "60px" }}>{product?.name}</h1>
      {description && documentToReactComponents(description)}
      {product?.images && <Gallery images={product?.images} />}
      <Contact />
    </Wrapper>
  )
}
export const query = graphql`
  query gatsbyProductPage($id: String!) {
    contentfulProdukt(id: { eq: $id }) {
      id
      contentful_id
      name
      description {
        raw
      }
      images {
        fluid {
          src
          base64
          srcWebp
          srcSetWebp
        }
      }
    }
  }
`;
export default ProductPage
