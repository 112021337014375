import React, { useState, useMemo } from 'react';
import Image from "gatsby-image";
import * as Masonry from './styled'
import Lightbox from 'react-spring-lightbox';

const Gallery = ({ images }) => {
    function getRandomNumberBetween(min, max) {
        return Math.random() * (max - min) + min;
    }
    const [currentImageIndex, setCurrentIndex] = useState(0);

    const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

    const gotoNext = () =>
        currentImageIndex + 1 < images.length &&
        setCurrentIndex(currentImageIndex + 1);
    const lightboxImages = images.map((image) => { return { src: image.fluid.src } })
    const sizes = useMemo(() => [...new Array(images.length)].map((image) => {
        return {
            medium: getRandomNumberBetween(35, 55),
            large: getRandomNumberBetween(20, 35)
        }
    }), [images])
    const [lightboxOpen, setLightboxOpen] = useState(false)
    const handleClick = (i) => {
        setCurrentIndex(i)
        setLightboxOpen(true)
    }
    return (
        <Masonry.Wrapper>
            {Array.isArray(images) && images?.map((item, i) =>
                <Masonry.Item onClick={() => handleClick(i)} key={i} medium={sizes[i].medium} large={sizes[i].large} >
                    <Masonry.Background>
                        <Image
                            fluid={item.fluid}
                        />
                    </Masonry.Background>
                </Masonry.Item>
            )}
            <Lightbox
                onClose={() => setLightboxOpen(false)}
                isOpen={lightboxOpen}
                onPrev={gotoPrevious}
                onNext={gotoNext}
                images={lightboxImages}
                currentIndex={currentImageIndex}
                style={{ background: "rgba(0,0,0,0.8)" }}
            />
        </Masonry.Wrapper>
    )
}

export default Gallery
