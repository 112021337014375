import styled from "styled-components";
import { Link } from "gatsby";

type MasonryType = {
  medium: number;
  large: number;
};
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  padding: 40px 10px 60px 10px;
  @media ${({ theme }) => theme.medium} {
    padding: 60px 0 80px 0;
  }
`;

export const Item = styled.div<MasonryType>`
  height: 310px;
  margin: 10px 0;
  flex: 1 0 auto;
  width: 100%;
  @media ${({ theme }) => theme.medium} {
    margin: 10px 10px;
    width: ${({ medium }) => `${medium}%`};
  }
  @media ${({ theme }) => theme.large} {
    width: ${({ large }) => `${large}%`};
  }
  position: relative;
  cursor: pointer;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  > div {
    height: 100%;
    width: 100%;
  }
`;
